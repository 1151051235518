import React from "react"
import * as styles from "./styles.module.css"

const LegalBody = ({ bodyText, infoText }) => {
    return (
        <div className={styles.legal}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-3 offset-lg-1">
                        <p className={styles.info}
                        dangerouslySetInnerHTML={{ __html: infoText }}></p>
                    </div>
                    <div className="col-lg-7"
                    dangerouslySetInnerHTML={{ __html: bodyText }} 
                    ></div>
                </div>
            </div>
        </div>
    )
}

export default LegalBody