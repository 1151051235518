// extracted by mini-css-extract-plugin
export var blackish = "#121117";
export var colors = "\"../../assets/css/_colors.css\"";
export var fonts = "\"../../assets/css/_fonts.css\"";
export var gtWalsheim = "'gt-walsheim'";
export var info = "styles-module--info--93f5c";
export var legal = "styles-module--legal--9779a";
export var lightBg = "#FFF8E8";
export var periwinkle = "#665CFF";
export var purple = "undefined";
export var yellowBtn = "#FFE07B";