import React from "react"
import { graphql } from "gatsby"
import { Seo } from "../components/seo"
import Layout from "../components/layout"
import LegalBody from "../components/legalBody"
import LegalBanner from "../components/legalBanner"

export default function LegalPage({ data: { post } }) {
  return (
    <Layout>
      <div>
          <LegalBanner title={post.title}
            backgroundImage={post.bannerBackgroundImage.url}
            mobileBackgroundImage={post.mobileBackgroundImage.url}  
          />
          <LegalBody 
            bodyText={post.bodyText} 
            infoText={post.sidebarText}
          />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PostBySlug($slug: String) {
    post: datoCmsLegalPage(slug: { eq: $slug }) {
      id
      title
      sidebarText
      bodyText
      bannerBackgroundImage {
        url
      }
      mobileBackgroundImage {
        url
      }
      seo {
        title
        description
        image {
          url
        }
      }
    }
  }
`

export const Head = ({ data }) => {
  return (
    <Seo 
      title={data.post.seo.title}
      description={data.post.seo.description}
      image={data.post.seo.image}
    />
  )
}